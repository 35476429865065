import { useState, useEffect } from "react"
import { Loader2 } from "lucide-react"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"
import api from "@/utils/api"
import type { Node, NodesResponse } from "@/types/node"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { formatDate } from "@/lib/utils"
import { useNavigate } from "react-router-dom"

export function NodesPage() {
    const [nodes, setNodes] = useState<Node[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState("")
    const { toast } = useToast()
    const navigate = useNavigate()

    const fetchNodes = async (page: number = 1) => {
        setIsLoading(true)
        try {
            const response = await api.get<NodesResponse>(
                `/nodes?page=${page}&search=${searchQuery}`
            )
            setNodes(response.data.nodes)
            setCurrentPage(response.data.pagination.currentPage)
            setTotalPages(response.data.pagination.totalPages)
        } catch (error: any) {
            toast({
                variant: "destructive",
                title: "Error",
                description: error.response?.data?.message || "Failed to fetch nodes"
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchNodes(1)
        }, 500)

        return () => clearTimeout(timer)
    }, [searchQuery])

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Nodes</h1>
            </div>

            <div className="flex items-center space-x-2">
                <Input
                    placeholder="Search nodes..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="max-w-sm"
                />
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <Loader2 className="h-8 w-8 animate-spin text-primary" />
                </div>
            ) : (
                <div className="rounded-md border">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Site URL</TableHead>
                                <TableHead>Cloud ID</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Created At</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {nodes.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} className="text-center">
                                        No nodes found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                nodes.map((node) => (
                                    <TableRow key={node._id} className="cursor-pointer hover:bg-muted/50" onClick={() => navigate(`/nodes/${node._id}`)}>
                                        <TableCell>{node.siteUrl}</TableCell>
                                        <TableCell>{node.cloudId}</TableCell>
                                        <TableCell>
                                            <span
                                                className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${node.status === 'active'
                                                    ? 'bg-green-100 text-green-800'
                                                    : 'bg-red-100 text-red-800'
                                                    }`}
                                            >
                                                {node.status}
                                            </span>
                                        </TableCell>
                                        <TableCell>{formatDate(node.createdAt)}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                    {totalPages > 1 && (
                        <div className="flex items-center justify-end space-x-2 py-4 px-4">
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() => fetchNodes(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </Button>
                            <span className="text-sm text-gray-600">
                                Page {currentPage} of {totalPages}
                            </span>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() => fetchNodes(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
