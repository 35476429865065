"use client";

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import metricLabels from "@/labels.json"; // Import the JSON file

interface Metric {
  _id: string;
  triggerKey: string;
  isLatest: boolean;
  data: Record<string, unknown>;
  timestamp: string;
}

interface BasicMetricsProps {
  triggerCount: number;
  metrics: Metric[];
}

type MetricLabels = {
  [key: string]: string; // Updated to allow dynamic mapping for nested keys
};

const formatNestedData = (
  data: Record<string, unknown> | undefined,
  labels: MetricLabels
) => {
  if (!data) return [];
  return Object.entries(data).map(([key, value]) => ({
    label: labels[key] || key, // Use the label from JSON if available, otherwise fallback to key
    value,
  }));
};

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(date);
};

const formatMetrics = (metrics: Metric[], labels: MetricLabels) => {
  return metrics.map((metric) => {
    const firstValue = Object.values(metric.data)[0];
    const isNumeric = typeof firstValue === "number";

    // Special case: Extract only the value for specific triggers
    const isSpecialCase =
      metric.triggerKey === "server-InfoBuildDate" ||
      metric.triggerKey === "current-JiraVersion" ||
      metric.triggerKey === "selected-TimeTracking" ||
      metric.triggerKey === "server-InfoBuildNumber";
    const value =
      isSpecialCase && typeof firstValue === "object" && firstValue !== null
        ? Object.values(firstValue)[0] // Extract the value from the object
        : firstValue;

    // Check if the metric is 'server-InfoBuildDate' and format the timestamp
    const formattedTimestamp =
      metric.triggerKey === "server-InfoBuildDate" && typeof value === "string"
        ? formatTimestamp(value)
        : value;

    return {
      label: labels[metric.triggerKey] || metric.triggerKey,
      value: isNumeric || isSpecialCase ? formattedTimestamp : null,
      fullData: isNumeric || isSpecialCase ? null : metric.data,
      triggerKey: metric.triggerKey,
    };
  });
};

export function BasicMetrics({ triggerCount, metrics }: BasicMetricsProps) {
  const formattedMetrics = formatMetrics(metrics, metricLabels);

  const specialMetrics = formattedMetrics.filter(
    (metric) =>
      metric.triggerKey === "server-InfoBuildDate" ||
      metric.triggerKey === "current-JiraVersion" ||
      metric.triggerKey === "server-InfoBuildNumber" ||
      metric.triggerKey === "selected-TimeTracking"
  );

  const avgResolutionTimeMetric = formattedMetrics.find(
    (metric) => metric.triggerKey === "avg-resolution-time"
  );
  const timeTrackingOptionsMetric = formattedMetrics.find(
    (metric) => metric.triggerKey === "time-TrackingOptions"
  );
  const projectCountByTypeMetric = formattedMetrics.find(
    (metric) => metric.triggerKey === "project-CountByType"
  );
  const projectCountByCategoryMetric = formattedMetrics.find(
    (metric) => metric.triggerKey === "project-CountByCategory"
  );


  // Categorizing metrics based on the JSON labels

  const projectMetricsOrder = [
    "total-ProjectCount",
    "total-project-types",
    "total-projectCategories-Count",
  ];

  const projectMetrics = formattedMetrics
    .filter((metric) => projectMetricsOrder.includes(metric.triggerKey))
    .sort(
      (a, b) =>
        projectMetricsOrder.indexOf(a.triggerKey) -
        projectMetricsOrder.indexOf(b.triggerKey)
    );

  const fieldMetrics = formattedMetrics.filter((metric) =>
    ["total-customField-count"].includes(metric.triggerKey)
  );

  const issueMetricsOrder = [
    "total-issue-count",
    "created-Last30Days",
    "resolved-Last30Days",
    "total-UnresolvedIssueCount",
  ];

  const issueMetrics = formattedMetrics
    .filter((metric) => issueMetricsOrder.includes(metric.triggerKey))
    .sort(
      (a, b) =>
        issueMetricsOrder.indexOf(a.triggerKey) -
        issueMetricsOrder.indexOf(b.triggerKey)
    );

  const otherMetricsOrder = [
      "priority-SchemeCount",
      "total-screenScheme-count",
      "permission-scheme-count",
      "total-workflow-count",
    ];
  const otherMetrics = formattedMetrics.filter((metric) =>
    otherMetricsOrder.includes(metric.triggerKey))
    .sort(
      (a, b) =>
        otherMetricsOrder.indexOf(a.triggerKey) -
        otherMetricsOrder.indexOf(b.triggerKey)
    );

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl">Basic Metrics</CardTitle>
        <CardDescription>Overview of all Basic triggers and their data</CardDescription>
      </CardHeader>
      <CardContent>
        {/* Divider Line above Special Metrics */}
        <div className="border-t border-gray-300 mt-6" />

        <div className="grid grid-cols-2 gap-4 mt-6">
          {specialMetrics.map((metric, index) => (
            <Carousel key={index} className="w-full">
              <CarouselContent>
                <CarouselItem>
                  <Card className="w-full h-18">
                    <CardContent className="flex items-center justify-center h-full">
                      <div className="text-center">
                        <p className="text-lg font-medium">{metric.label}</p>
                        {metric.value !== null ? (
                          <p className="text-lg font-medium">{metric.value}</p>
                        ) : (
                          <pre className="text-sm font-mono text-left overflow-auto">
                            {JSON.stringify(metric.fullData, null, 2)}
                          </pre>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          ))}
        </div>

        {/* Divider Line above Avg Resolution Time */}
        <div className="border-t border-gray-300 mt-6" />

        {/* Avg Resolution Time and Time Tracking Options */}
        <div className="grid grid-cols-2 gap-4 mt-6">
          {avgResolutionTimeMetric && (
            <Carousel>
              <CarouselContent>
                <CarouselItem>
                  <Card className="w-full h-18">
                    <CardContent className="flex items-center justify-center h-full">
                      <div className="text-center">
                        <p className="text-lg font-medium">{avgResolutionTimeMetric.label}</p>
                        {avgResolutionTimeMetric.value !== null ? (
                          <p className="text-lg font-medium">{avgResolutionTimeMetric.value}</p>
                        ) : (
                          <div className="overflow-x-auto">
                            <table className="min-w-full text-sm">
                              <thead>
                                <tr>
                                  <th className="border-b p-2">Key</th>
                                  <th className="border-b p-2">Value</th>
                                </tr>
                              </thead>
                              <tbody>
      {avgResolutionTimeMetric.fullData &&
        formatNestedData(
          avgResolutionTimeMetric.fullData.averageTimeToResolution as Record<string, unknown>,
          metricLabels
        ).map(({ label, value }) => (
          <tr key={label}>
            <td className="border-b p-2">{label}</td>
            <td className="border-b p-2">{String(value)}</td> {/* Safely convert value to string */}
          </tr>
        ))}
    </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          )}

          {timeTrackingOptionsMetric && (
            <Carousel>
              <CarouselContent>
                <CarouselItem>
                  <Card className="w-full h-18">
                    <CardContent className="flex items-center justify-center h-full">
                      <div className="text-center">
                        <p className="text-lg font-medium">{timeTrackingOptionsMetric.label}</p>
                        <div className="overflow-x-auto">
                          <table className="min-w-full text-sm">
                            <thead>
                              <tr>
                                <th className="border-b p-2">Key</th>
                                <th className="border-b p-2">Value</th>
                              </tr>
                            </thead>
                            <tbody>
                    {timeTrackingOptionsMetric.fullData &&
                      formatNestedData(
                        timeTrackingOptionsMetric.fullData.timeTrackingOptions as Record<string, unknown>,
                        metricLabels
                      ).map(({ label, value }) => (
                        <tr key={label}>
                          <td className="border-b p-2">{label}</td>
                          <td className="border-b p-2">{String(value)}</td> {/* Safely convert value to string */}
                        </tr>
                      ))}
                  </tbody>
                          </table>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          )}
        </div>

        {/* Divider Line above Project Counts */}
        <div className="border-t border-gray-300 mt-6" />

        <div className="grid grid-cols-2 gap-4 mt-6">
          {projectCountByTypeMetric && (
            <Carousel>
              <CarouselContent>
                <CarouselItem>
                  <Card className="w-full h-18">
                    <CardContent className="flex items-center justify-center h-full">
                      <div className="text-center">
                        <p className="text-lg font-medium">{projectCountByTypeMetric.label}</p>
                        <div className="overflow-x-auto">
                          <table className="min-w-full text-sm">
                            <thead>
                              <tr>
                                <th className="border-b p-2">Type</th>
                                <th className="border-b p-2">Count</th>
                              </tr>
                            </thead>
                            <tbody>
                      {projectCountByTypeMetric.fullData &&
                        formatNestedData(
                          projectCountByTypeMetric.fullData.projectCounts as Record<string, unknown>,
                          metricLabels
                        ).map(({ label, value }) => (
                          <tr key={label}>
                            <td className="border-b p-2">{label}</td>
                            <td className="border-b p-2">{String(value)}</td> {/* Convert value to string */}
                          </tr>
                        ))}
                    </tbody>
                          </table>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          )}

{projectCountByCategoryMetric && (
            <Carousel>
              <CarouselContent>
                <CarouselItem>
                  <Card className="w-full h-18">
                    <CardContent className="flex items-center justify-center h-full">
                      <div className="text-center">
                        <p className="text-lg font-medium">{projectCountByCategoryMetric.label}</p>
                        <div className="overflow-x-auto">
                          <table className="min-w-full text-sm">
                            <thead>
                              <tr>
                                <th className="border-b p-2">Category</th>
                                <th className="border-b p-2">Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectCountByCategoryMetric.fullData &&
                                Object.entries(projectCountByCategoryMetric.fullData?.categoryCounts || {}).map(
                                  ([category, count]) => (
                                    <tr key={category}>
                                      <td className="border-b p-2">{category}</td>
                                      <td className="border-b p-2">{count}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          )}
        </div>

        {/* Divider Line above Regular Metrics */}
        <div className="border-t border-gray-300 mt-6" />

        {/* Project Related Metrics */}
        <div className="mt-6">
          <h2 className="text-lg font-bold mb-4">PROJECT RELATED METRICS</h2>
          <div className="grid grid-cols-4 gap-4">
            {projectMetrics.map((metric, index) => (
              <Card key={index}>
                <CardContent className="text-center">
                  <p className="text-lg font-medium">{metric.label}</p>
                  <p className="text-lg font-medium">{metric.value}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        {/* Field Related Metrics */}
        <div className="mt-6">
          <h2 className="text-lg font-bold mb-4">FIELD RELATED METRICS</h2>
          <div className="grid grid-cols-4 gap-4">
            {fieldMetrics.map((metric, index) => (
              <Card key={index}>
                <CardContent className="text-center">
                  <p className="text-lg font-medium">{metric.label}</p>
                  <p className="text-lg font-medium">{metric.value}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        {/* Issues Related Metrics */}
        <div className="mt-6">
          <h2 className="text-lg font-bold mb-4">ISSUES RELATED METRICS</h2>
          <div className="grid grid-cols-4 gap-4">
            {issueMetrics.map((metric, index) => (
              <Card key={index}>
                <CardContent className="text-center">
                  <p className="text-lg font-medium">{metric.label}</p>
                  <p className="text-lg font-medium">{metric.value}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        {/* Other Metrics */}
        <div className="mt-6">
          <h2 className="text-lg font-bold mb-4">OTHER METRICS</h2>
          <div className="grid grid-cols-4 gap-4">
            {otherMetrics.map((metric, index) => (
              <Card key={index}>
                <CardContent className="text-center">
                  <p className="text-lg font-medium">{metric.label}</p>
                  <p className="text-lg font-medium">{metric.value}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}