"use client"

import React, { useState, useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import api from "@/utils/api"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"; // Your custom Shadcn table components
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
  } from "@/components/ui/pagination"; // Pagination components
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"

import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

// Zod validation schema
const formSchema = z.object({
  name: z.string().min(2, {
    message: "Name is required and should have at least 2 characters.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
  category: z.string().nonempty({
    message: "Issue category is required.",
  }),
  description: z.string().min(2, {
    message: "Description must be at least 2 characters long.",
  }),
})

interface Ticket {
  name: string
  email: string
  category: string
  description: string
  createdAt: string
}

export function SupportPage() {
  const [showForm, setShowForm] = useState(false)

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      category: "",
      description: "",
    },
  })


  /*************************************************** */
  /*************************************************** */
  /*************************************************** */
  /*************************************************** */

  
  
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [paginatedTickets, setPaginatedTickets] = useState<Ticket[]>([]); // Sliced tickets for the current page
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Page limit is set to 10
  
    
  
  const fetchTickets = async () => {
    setIsLoading(true);
    try {
      const response = await api.get<{ tickets: Ticket[] }>("/support/tickets");
      console.log("Fetched tickets:", response.data.tickets); // Log fetched tickets
      setTickets(response.data.tickets); // Store the full ticket list
      setTotalPages(Math.ceil(response.data.tickets.length / itemsPerPage)); // Calculate total pages based on tickets
    } catch (error: any) {
      console.error("Error fetching tickets:", error);
      setMessage(error.response?.data?.message || "Failed to fetch tickets.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: any) => {
      try {
        setIsLoading(true)
        const response = await api.post("/support/tickets", data)
        setMessage(response.data.message || "Ticket created successfully.")
        fetchTickets() // Refresh ticket list
        form.reset() // Reset form fields
        setShowForm(false)
      } catch (error: any) {
        console.error("Error during ticket submission:", error)
        setMessage(error.response?.data?.message || "Error occurred while submitting the ticket.")
      } finally {
        setIsLoading(false)
      }
    }
    
      
  const handleCancel = () => {
    form.reset()
    setShowForm(false)
  }

  const paginateTickets = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedTickets(tickets.slice(startIndex, endIndex)); // Slice tickets for the current page
  };

  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return; // Prevent changing to invalid pages
    setCurrentPage(page);
  };
  
  useEffect(() => {
    fetchTickets(); // Fetch tickets on component mount
  }, []);
  useEffect(() => {
    paginateTickets(); // Paginate tickets when tickets or page changes
  }, [tickets, currentPage]);
  
///////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="space-y-4 px-4 py-6 w-full">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Support</h1>
      </div>

      {!showForm && (
        <Button onClick={() => setShowForm(true)} className="mt-4">
          Raise a Support Ticket
        </Button>
      )}

      {showForm && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="mt-4 p-6 border rounded-md space-y-4">
            <h3 className="text-lg font-semibold">Raise a Support Ticket</h3>

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Your Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Your Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="category"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Issue Category</FormLabel>
                  <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select Issue Category" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Bug">Bug</SelectItem>
                        <SelectItem value="Feature Request">Feature Request</SelectItem>
                        <SelectItem value="General Query">General Query</SelectItem>
                        <SelectItem value="Other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea placeholder="Describe the issue" {...field} rows={4} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-between mt-4">
              <Button variant="outline" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span>Loading...</span>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </form>
        </Form>
      )}

{paginatedTickets.length > 0 ? (
        <div className="mt-6 overflow-x-auto border-l border-r border-gray-300">
          <div className="rounded-md border">
            <Table className="w-full min-w-[1024px] table-fixed">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-16">#</TableHead>
                  <TableHead className="w-[20%]">Name</TableHead>
                  <TableHead className="w-[15%]">Category</TableHead>
                  <TableHead className="w-[35%] truncate">Description</TableHead>
                  <TableHead className="w-[20%]">Created At</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {paginatedTickets.map((ticket, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </TableCell>
                    <TableCell>{ticket.name}</TableCell>
                    <TableCell>{ticket.category}</TableCell>
                    <TableCell>{ticket.description}</TableCell>
                    <TableCell>{new Date(ticket.createdAt).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      ) : (
        <p>No support tickets found.</p>
      )}

      {isLoading && (
        <div className="flex justify-center items-center min-h-[200px]">
          Loading...
        </div>
      )}

      {message && <div className="mt-4 text-sm text-gray-500">{message}</div>}

      {/* Pagination moved below the table */}
      {paginatedTickets.length > 0 && (
        <div className="mt-6">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  href="#"
                  onClick={() => handlePageChange(currentPage - 1)}
                  className={currentPage === 1 ? "cursor-not-allowed opacity-50" : ""}
                />
              </PaginationItem>
              {[...Array(totalPages)].map((_, idx) => (
                <PaginationItem key={idx}>
                  <PaginationLink
                    href="#"
                    onClick={() => handlePageChange(idx + 1)}
                    isActive={currentPage === idx + 1}
                  >
                    {idx + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem>
                <PaginationNext
                  href="#"
                  onClick={() => handlePageChange(currentPage + 1)}
                  className={currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center min-h-[200px]">
          Loading...
        </div>
      )}

      {message && <div className="mt-4 text-sm text-gray-500">{message}</div>}
    </div>
  );
}