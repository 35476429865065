import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useToast } from "@/components/ui/use-toast"
import axios from "axios"
import { API_BASE_URL } from "../config"

export function SignupPage() {
    const navigate = useNavigate()
    const { toast } = useToast()
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            await axios.post(`${API_BASE_URL}/users/signup`, { email })

            toast({
                title: "Success",
                description: "Please check your email to complete signup.",
            })
            navigate('/login')
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: err.response.data.message || "Signup failed. Please try again.",
                })
            } else {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "An unexpected error occurred. Please try again.",
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="min-h-screen w-full bg-gray-50 flex items-center justify-center">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-sm">
                <div className="text-center mb-8">
                    <h1 className="text-2xl font-bold tracking-tight">
                        Create your account
                    </h1>
                    <p className="text-gray-500 mt-2">
                        Enter your email to get started
                    </p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="email">Email address</Label>
                        <Input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isLoading}
                            required
                        />
                    </div>

                    <Button
                        type="submit"
                        className="w-full"
                        disabled={isLoading}
                    >
                        {isLoading ? "Signing up..." : "Sign up"}
                    </Button>

                    <p className="text-center text-sm text-gray-500">
                        Already have an account?{" "}
                        <button
                            type="button"
                            className="text-primary hover:underline"
                            onClick={() => navigate('/login')}
                        >
                            Sign in
                        </button>
                    </p>
                </form>
            </div>
        </div>
    )
} 