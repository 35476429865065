import { AppSidebar } from "../app-sidebar"
import { SidebarTrigger } from "../ui/sidebar"
import { Outlet } from "react-router-dom"

export function DashboardLayout() {
    return (
        <div className="flex min-h-screen">
            <AppSidebar />
            <main className="flex-1">
                <div className="container mx-auto p-6">
                    <div className="flex items-center gap-4 mb-8">
                        <SidebarTrigger />
                    </div>
                    <Outlet />
                </div>
            </main>
        </div>
    )
} 