import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loader2, ArrowLeft, Settings, RefreshCw } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs";
import api from "@/utils/api";
import { BasicMetrics } from "@/components/nodes/basic-metrics";
import { DerivedMetrics } from "@/components/nodes/derived-metrics";

interface NodeDetails {
    _id: string;
    cloudId: string;
    moduleKey: string;
    siteUrl: string;
    status: string;
    environmentType: string;
    company: {
        _id: string;
        name: string;
        domain: string;
    };
    triggerCount: number;
    createdAt: string;
    updatedAt: string;
}

interface Metric {
    _id: string;
    triggerKey: string;
    isLatest: boolean;
    data: Record<string, unknown>;
    timestamp: string;
    derived: boolean;
    type: string;
}

export function NodeDetailsPage() {
    const [node, setNode] = useState<NodeDetails | null>(null);
    const [basicMetrics, setBasicMetrics] = useState<Metric[]>([]);
    const [derivedMetrics, setDerivedMetrics] = useState<Metric[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const { toast } = useToast();

    useEffect(() => {
        const fetchNodeDetails = async () => {
            try {
                const response = await api.get<{ node: NodeDetails }>(`/nodes/${id}`);
                setNode(response.data.node);
            } catch (error: any) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: error.response?.data?.message || "Failed to fetch node details",
                });
                navigate("/nodes");
            }
        };

        const fetchMetrics = async () => {
            try {
                const response = await api.get<{ metrics: Metric[] }>(`/nodes/${id}/metrics`);
                const basicMetricsData = response.data.metrics.filter(
                    (metric) => metric.type === "basic" && metric.isLatest
                );
                const derivedMetricsData = response.data.metrics.filter(
                    (metric) => metric.type === "derived" && metric.isLatest
                );

                setBasicMetrics(basicMetricsData);
                setDerivedMetrics(derivedMetricsData);
            } catch (error: any) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: error.response?.data?.message || "Failed to fetch metrics",
                });
            }
        };

        setIsLoading(true);
        Promise.all([fetchNodeDetails(), fetchMetrics()]).finally(() => setIsLoading(false));
    }, [id]);

    const handleFetchData = async () => {
        setIsFetching(true);
        try {
            const response = await api.post<{ message: string }>(`/nodes/${id}/fetch-data`);
            toast({
                title: "Success",
                description: response.data.message,
            });
    
            // After successful fetch, get the latest node details and metrics
            const nodeResponse = await api.get<{ node: NodeDetails }>(`/nodes/${id}`);
            setNode(nodeResponse.data.node);
    
            const metricsResponse = await api.get<{ metrics: Metric[] }>(`/nodes/${id}/metrics`);
            const basicMetricsData = metricsResponse.data.metrics.filter(
                (metric) => metric.type === "basic" && metric.isLatest
            );
            const derivedMetricsData = metricsResponse.data.metrics.filter(
                (metric) => metric.type === "derived" && metric.isLatest
            );
    
            setBasicMetrics(basicMetricsData);
            setDerivedMetrics(derivedMetricsData);
        } catch (error: any) {
            toast({
                variant: "destructive",
                title: "Error",
                description: error.response?.data?.message || "Failed to fetch data",
            });
        } finally {
            setIsFetching(false);
        }
    };
    

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
            </div>
        );
    }

    if (!node) return null;

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <Button variant="ghost" size="sm" onClick={() => navigate("/nodes")}>
                        <ArrowLeft className="h-4 w-4 mr-2" />
                        Back to Nodes
                    </Button>
                </div>
                <div className="flex items-center gap-2">
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleFetchData}
                        disabled={isFetching}
                    >
                        {isFetching ? (
                            <>
                                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                Fetching...
                            </>
                        ) : (
                            <>
                                <RefreshCw className="h-4 w-4 mr-2" />
                                Fetch Data
                            </>
                        )}
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => navigate(`/nodes/${id}/settings`)}
                    >
                        <Settings className="h-4 w-4 mr-2" />
                        Settings
                    </Button>
                </div>
            </div>

            <div className="grid gap-6">

            <Card>
                    <CardHeader>
                        <CardTitle className="text-2xl">Node Details</CardTitle>
                        <CardDescription>
                            Detailed information about the node
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Tabs defaultValue="basic" className="space-y-4">
                            <TabsList>
                                <TabsTrigger value="basic">Basic Info</TabsTrigger>
                                <TabsTrigger value="company">Company</TabsTrigger>
                            </TabsList>

                            <TabsContent value="basic" className="space-y-4">
                                <div className="grid gap-4 md:grid-cols-2">
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Site URL</h4>
                                        <p className="text-sm">{node.siteUrl}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Cloud ID</h4>
                                        <p className="text-sm">{node.cloudId}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Module Key</h4>
                                        <p className="text-sm">{node.moduleKey}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Status</h4>
                                        <span
                                            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${node.status === 'active'
                                                ? 'bg-green-100 text-green-800'
                                                : 'bg-red-100 text-red-800'
                                                }`}
                                        >
                                            {node.status}
                                        </span>
                                    </div>
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Environment Type</h4>
                                        <p className="text-sm">{node.environmentType}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Trigger Count</h4>
                                        <p className="text-sm">{node.triggerCount}</p>
                                    </div>
                                </div>
                            </TabsContent>

                            <TabsContent value="company" className="space-y-4">
                                <div className="grid gap-4 md:grid-cols-2">
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Company Name</h4>
                                        <p className="text-sm">{node.company.name}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <h4 className="font-medium text-sm text-muted-foreground">Domain</h4>
                                        <p className="text-sm">{node.company.domain}</p>
                                    </div>
                                </div>
                            </TabsContent>
                        </Tabs>

                        <div className="mt-6 pt-6 border-t space-y-2">
                            <div className="flex justify-between text-sm text-muted-foreground">
                                <span>Created</span>
                                <span>{node.createdAt}</span>
                            </div>
                            <div className="flex justify-between text-sm text-muted-foreground">
                                <span>Last Updated</span>
                                <span>{node.updatedAt}</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <BasicMetrics triggerCount={node.triggerCount} metrics={basicMetrics} />
                <DerivedMetrics metrics={derivedMetrics} /> {/* Pass derived metrics here */}

                
            </div>
        </div>
    );
}
