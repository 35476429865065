import { useState, useEffect } from "react";
import { Loader2 } from "lucide-react";
import api from "@/utils/api";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

interface Log {
    timestamp: string;
    message: string;
    metadata?: {
        url?: string;
        userEmail?: string;
    };
    userEmail?: string;
}

export function CombinedLogsPage() {
    const [logs, setLogs] = useState<Log[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [statusFilter, setStatusFilter] = useState<string | undefined>(undefined);
    const [environmentTypeFilter, setEnvironmentTypeFilter] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchLogs = async (page = 1) => {
        setIsLoading(true);
        setErrorMessage("");

        const queryParams = new URLSearchParams({
            page: String(page),
            search: searchQuery,
            ...(statusFilter ? { status: statusFilter } : {}),
            ...(environmentTypeFilter ? { environmentType: environmentTypeFilter } : {}),
        }).toString();

        try {
            const response = await api.get(`/logs/activity_logs?${queryParams}`);
            setLogs(response.data.activityLogs || response.data.nodes);
            setCurrentPage(response.data.pagination.currentPage);
            setTotalPages(response.data.pagination.totalPages);
        } catch (error) {
            setErrorMessage("Failed to fetch logs. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchLogs(1);
        }, 500);
        return () => clearTimeout(timer);
    }, [searchQuery, statusFilter, environmentTypeFilter]);

    const handlePrevious = () => {
        if (currentPage > 1) fetchLogs(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < totalPages) fetchLogs(currentPage + 1);
    };

    return (
        <div className="space-y-4">
            {/* Header Section */}
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Activity Logs</h1>
            </div>

            {/* Search Input */}
            <div className="flex items-center space-x-2">
                <Input
                    type="text"
                    placeholder="Search logs..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="max-w-sm"
                />
            </div>

            {/* Error Message */}
            {errorMessage && (
                <p className="text-red-500 text-sm text-center mt-2">{errorMessage}</p>
            )}

            {/* Log Table or Loader */}
            {isLoading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <Loader2 className="animate-spin text-gray-500" size={32} />
                </div>
            ) : logs.length > 0 ? (
                <div className="overflow-x-auto border-l border-r border-gray-300">
                    <div className="rounded-md border">
                        <Table className="w-full min-w-[1024px] table-fixed">
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="w-16">#</TableHead>
                                    <TableHead className="w-[15%]">Message</TableHead>
                                    <TableHead className="w-[40%] truncate">URL</TableHead>
                                    <TableHead>User Emails</TableHead>
                                    <TableHead>Timestamp</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {logs.map((log, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {(currentPage - 1) * 10 + index + 1}
                                        </TableCell>
                                        <TableCell className="w-[30%]">{log.message}</TableCell>
                                        <TableCell className="w-[25%] truncate">
                                            {log.metadata?.url || "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {log.userEmail || log.metadata?.userEmail || "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(log.timestamp).toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            ) : (
                <p className="text-center text-gray-500">No logs found.</p>
            )}

            {/* Pagination Controls */}
            <div className="flex items-center justify-end space-x-2 py-4">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <span className="text-sm">
                    Page {currentPage} of {totalPages}
                </span>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
        </div>
    );
}