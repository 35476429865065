import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loader2, ArrowLeft } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import api from "@/utils/api";
import { ScrollArea } from "@/components/ui/scroll-area"
interface NodeSettings {
  _id: string;
  cloudId: string;
  moduleKey: string;
  siteUrl: string;
  status: string;
  environmentType: string;
}

interface ExecutionLog {
  id: string;
  status: string;
  totalTriggers: number;
  successCount: number;
  failureCount: number;
  startTime: string;
  endTime: string;
  createdAt: string;
  triggerExecutions: TriggerExecution[];
}

interface TriggerExecution {
  key: string;
  status: string;
  statusCode: number;
  response: {
    message: string;
  };
  executedAt: string;
  duration: number;
}

export function NodeSettingsPage() {
  const [settings, setSettings] = useState<NodeSettings | null>(null);
  const [logs, setLogs] = useState<ExecutionLog[]>([]);
  const [selectedLog, setSelectedLog] = useState<ExecutionLog | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLogsLoading, setIsLogsLoading] = useState(false);
  const [logsPage, setLogsPage] = useState(1);
  const [dialogPage, setDialogPage] = useState(1);
  const logsPageSize = 10;
  const dialogPageSize = 5;
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [tickets, setTickets] = useState([])
  const [cronFrequency, setCronFrequency] = useState('once a day');
  const [storedFrequency, setStoredFrequency] = useState(null); // To store the submitted value

  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const calculateTimeDifference = (startTime: string, endTime: string): string => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    const diffMs = end.getTime() - start.getTime();
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleCronFrequencyChange = (event) => {
    setCronFrequency(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      // Replace `id` with the actual `cloudId` that your API expects
      const response = await api.put(`/nodes/${id}/frequency`, {
        newFrequency: cronFrequency,
      });
  
      toast({
        variant: "default",
        title: "Success",
        description: response.data.message || "Cron frequency saved successfully!",
      });
  
      // Optionally update the stored value
      setStoredFrequency(cronFrequency);
    } catch (error: any) {
      console.error("Failed to save cron frequency:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.response?.data?.message || "Failed to save cron frequency",
      });
    }
  };
  

  const isFetching = useRef(false);

useEffect(() => {
  const fetchData = async () => {
    if (isFetching.current) return;
    isFetching.current = true;

    setIsLoading(true);
    setIsLogsLoading(true);

    try {
      // Fetch node settings
      const response = await api.get<{ node: NodeSettings }>(`/nodes/${id}`);
      setSettings(response.data.node);

      // Fetch tickets only if cloudId exists
      if (response.data.node?.cloudId) {
        try {
          const ticketsResponse = await api.get(
            `/nodes/${response.data.node.cloudId}/ticketz`
          );
          setTickets(ticketsResponse.data.tickets);
        } catch (error) {
          console.error("Failed to fetch tickets:", error);
        }
      }

      // Fetch cron frequency
      try {
        const frequencyResponse = await api.get(`/nodes/${id}/frequency`);
        setStoredFrequency(frequencyResponse.data.frequency);
      } catch (error) {
        console.error("Failed to fetch cron frequency:", error);
      }

      // Fetch execution logs
      const logsResponse = await api.get<{ logs: ExecutionLog[] }>(`/nodes/${id}/logs`, {
        params: {
          page: logsPage,
          limit: logsPageSize,
        },
      });
      setLogs(logsResponse.data.logs);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description:
          error.response?.data?.message || "Failed to fetch node settings or logs",
      });
      navigate("/nodes");
    } finally {
      setIsLoading(false);
      setIsLogsLoading(false);
      isFetching.current = false;
    }
  };

  fetchData();
}, [id, logsPage, navigate, toast]);

  

  const handleRowClick = (log: ExecutionLog) => {
    setSelectedLog(log);
    setDialogPage(1); // Reset dialog pagination when opening
  };

  const getStatusCodeClass = (statusCode: number) => {
    if (statusCode === 200) {
      return "bg-green-100 text-green-800"; // Green background for 200
    }
    return "bg-red-100 text-red-800"; // Red background for other status codes
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!settings) return null;

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => navigate(`/nodes/${id}`)}
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Node Details
        </Button>
      </div>

      <div className="grid gap-6">
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl">Node Settings</CardTitle>
            <CardDescription>Configure your node settings</CardDescription>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="general" className="space-y-4">
              <TabsList>
                <TabsTrigger value="general">General</TabsTrigger>
                <TabsTrigger value="logs">Execution Logs</TabsTrigger>
                <TabsTrigger value="security">Security</TabsTrigger>
                <TabsTrigger value="tickets">Tickets</TabsTrigger>
                <TabsTrigger value="cron">Cron Frequency</TabsTrigger>

              </TabsList>

              <TabsContent value="cron" className="space-y-4">
                <h4 className="font-medium text-sm">Cron Frequency</h4>
                <p className="text-sm text-muted-foreground">Select the frequency for cron execution:</p>
                <select
                  value={cronFrequency}
                  onChange={handleCronFrequencyChange}
                  className="p-2 border rounded-md"
                >
                  <option value="everyMinute">Every Minute</option>
                  <option value="weekly">Weekly</option>
                  <option value="biweekly">Biweekly</option>
                  <option value="monthly">Monthly</option>
                </select>

                <button
                  onClick={handleSubmit}
                  className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Submit
                </button>

                {storedFrequency && (
                  <p className="text-sm mt-4">
                    Current Frequency: <span className="font-medium">{storedFrequency}</span>
                  </p>
                )}
              </TabsContent>


              <TabsContent value="security">
                <p className="text-sm text-muted-foreground">
                  Security settings coming soon...
                </p>
              </TabsContent>

              <TabsContent value="tickets" className="space-y-4">
                <h4 className="font-medium text-sm">Associated Tickets</h4>
                {tickets.length > 0 ? (
                  <ScrollArea className="h-72 w-full rounded-md border"> {/* Add ScrollArea here */}
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Ticket ID</TableHead>
                          <TableHead>Email</TableHead>
                          <TableHead>Category</TableHead>
                          <TableHead>Summary</TableHead>
                          <TableHead>Priority</TableHead>
                          <TableHead>Cloud ID</TableHead>
                          <TableHead>Created At</TableHead>
                          <TableHead>Updated At</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {tickets.map((ticket: any, index: number) => (
                          <TableRow key={index}>
                            <TableCell>{ticket.ticketId}</TableCell>
                            <TableCell>{ticket.email}</TableCell>
                            <TableCell>{ticket.category}</TableCell>
                            <TableCell>{ticket.summary}</TableCell>
                            <TableCell>{ticket.priority}</TableCell>
                            <TableCell>{ticket.cloudId}</TableCell>
                            <TableCell>{new Date(ticket.createdAt).toLocaleString()}</TableCell>
                            <TableCell>{new Date(ticket.updatedAt).toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ScrollArea>
                ) : (
                  <p className="text-sm text-muted-foreground">
                    No tickets found for this node's cloudId.
                  </p>
                )}
              </TabsContent>

              <TabsContent value="logs">
                {isLogsLoading ? (
                  <div className="flex justify-center items-center min-h-[200px]">
                    <Loader2 className="h-6 w-6 animate-spin text-primary" />
                  </div>
                ) : logs.length > 0 ? (
                  <div className="overflow-x-auto">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Sr. No.</TableHead>
                          <TableHead>Status</TableHead>
                          <TableHead>Total Triggers</TableHead>
                          <TableHead>Success Count</TableHead>
                          <TableHead>Failure Count</TableHead>
                          <TableHead>Time Taken</TableHead>
                          <TableHead>Created At</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {logs.map((log, index) => (
                          <TableRow
                            key={log.id}
                            className="cursor-pointer"
                            onClick={() => handleRowClick(log)}
                          >
                            <TableCell>{index + 1 + (logsPage - 1) * logsPageSize}</TableCell>
                            <TableCell>
                              <span
                                className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${log.status === "completed"
                                  ? "bg-green-100 text-green-800"
                                  : log.status === "failed"
                                    ? "bg-red-100 text-red-800"
                                    : "bg-gray-100 text-gray-800"
                                  }`}
                              >
                                {log.status}
                              </span>
                            </TableCell>
                            <TableCell>{log.totalTriggers}</TableCell>
                            <TableCell>{log.successCount}</TableCell>
                            <TableCell>{log.failureCount}</TableCell>
                            <TableCell>
                              {calculateTimeDifference(log.startTime, log.endTime)}
                            </TableCell>
                            <TableCell>{formatTimestamp(log.createdAt)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination>
                      <PaginationContent>
                        <PaginationItem>
                          <PaginationPrevious
                            onClick={() => setLogsPage((prev) => Math.max(prev - 1, 1))}
                          />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink isActive>{logsPage}</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationNext
                            onClick={() => setLogsPage((prev) => prev + 1)}
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </div>
                ) : (
                  <p>No logs available</p>
                )}
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </div>

      <Dialog open={selectedLog !== null} onOpenChange={(open) => !open && setSelectedLog(null)}>
        <DialogContent style={{ width: "80%", maxWidth: "none" }}>
          {selectedLog && (
            <>
              <DialogHeader>
                <DialogTitle>Execution Logs</DialogTitle>
                <DialogDescription>
                  Detailed logs for execution with status {selectedLog.status}
                </DialogDescription>
              </DialogHeader>

              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Key</TableHead>
                      <TableHead>Status Code</TableHead>
                      <TableHead>Message</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {selectedLog.triggerExecutions
                      .slice(
                        (dialogPage - 1) * dialogPageSize,
                        dialogPage * dialogPageSize
                      )
                      .map((execution) => {
                        const statusCodeClass = getStatusCodeClass(execution.statusCode);

                        return (
                          <TableRow key={execution.key}>
                            <TableCell>{execution.key}</TableCell>
                            <TableCell>
                              <span
                                className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${statusCodeClass}`}
                              >
                                {execution.statusCode}
                              </span>
                            </TableCell>
                            <TableCell>{execution.response?.message || "N/A"}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <Pagination>
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationPrevious
                        onClick={() => setDialogPage((prev) => Math.max(prev - 1, 1))}
                      />
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink isActive>{dialogPage}</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationNext
                        onClick={() =>
                          setDialogPage(
                            (prev) =>
                              prev +
                              (selectedLog.triggerExecutions.length >
                                dialogPage * dialogPageSize
                                ? 1
                                : 0)
                          )
                        }
                      />
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              </div>
            </>
          )}
          <DialogFooter>
            <Button onClick={() => setSelectedLog(null)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}