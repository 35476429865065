import { useState, useEffect } from "react"
import { Plus } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"
import { UserTable } from "@/components/users/user-table"
import { AddUserDialog } from "@/components/users/add-user-dialog"
import { EditUserDialog } from "@/components/users/edit-user-dialog"
import { Loader2 } from "lucide-react"
import api from "@/utils/api"
import type { User, UsersResponse } from "@/types/user"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog"

export function UsersPage() {
    const [users, setUsers] = useState<User[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    const [userToDelete, setUserToDelete] = useState<string | null>(null)
    const { toast } = useToast()

    const fetchUsers = async (page: number = 1) => {
        setIsLoading(true)
        try {
            const response = await api.get<UsersResponse>(
                `/users?page=${page}&search=${searchQuery}`
            )
            setUsers(response.data.users)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to fetch users"
            })
        } finally {
            setIsLoading(false)
        }
    }

    // Debounce search to prevent multiple API calls
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchUsers(1)
        }, 500)

        return () => clearTimeout(timer)
    }, [searchQuery])

    const handleDelete = async (userId: string) => {
        try {
            await api.delete(`/users/${userId}`)
            toast({
                title: "Success",
                description: "User deleted successfully"
            })
            fetchUsers(currentPage)
        } catch (error: any) {
            toast({
                variant: "destructive",
                title: "Error",
                description: error.response?.data?.message || "Failed to delete user"
            })
        }
        setUserToDelete(null)
    }

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Users</h1>
                <Button onClick={() => setShowAddDialog(true)}>
                    <Plus className="mr-2 h-4 w-4" />
                    Add User
                </Button>
            </div>

            <div className="flex items-center space-x-2">
                <Input
                    placeholder="Search users..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="max-w-sm"
                />
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <Loader2 className="h-8 w-8 animate-spin text-primary" />
                </div>
            ) : (
                <UserTable
                    users={users}
                    isLoading={isLoading}
                    onEdit={(user) => {
                        setSelectedUser(user)
                        setShowEditDialog(true)
                    }}
                    onDelete={(userId) => setUserToDelete(userId)}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={fetchUsers}
                />
            )}

            <AlertDialog open={!!userToDelete} onOpenChange={() => setUserToDelete(null)}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently delete the user
                            and remove their data from our servers.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                            onClick={() => userToDelete && handleDelete(userToDelete)}
                            className="bg-red-600 hover:bg-red-700"
                        >
                            Delete
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <AddUserDialog
                open={showAddDialog}
                onOpenChange={setShowAddDialog}
                onSuccess={() => {
                    fetchUsers()
                    setShowAddDialog(false)
                }}
            />

            <EditUserDialog
                open={showEditDialog}
                onOpenChange={setShowEditDialog}
                user={selectedUser}
                onSuccess={() => {
                    fetchUsers()
                    setShowEditDialog(false)
                    setSelectedUser(null)
                }}
            />
        </div>
    )
} 