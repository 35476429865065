import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/auth-context'

export function ProtectedRoute({ children }: { children: React.ReactNode }) {
    const { isAuthenticated } = useAuth()
    const location = useLocation()

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location.pathname }} replace />
    }

    return <>{children}</>
} 