import { useState } from 'react'
import { LogOut, User, Settings } from "lucide-react"
import { useAuth } from '../contexts/auth-context'
import { useNavigate } from 'react-router-dom'
import { useToast } from "@/components/ui/use-toast"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar"
import { Button } from "./ui/button"

interface UserProfileProps {
    user: {
        name: string;
        email: string;
        role: string;
        avatarUrl?: string;
    }
}

export function UserProfile({ user }: UserProfileProps) {
    const { logout } = useAuth()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const { toast } = useToast()

    const handleLogout = async () => {
        try {
            setIsLoading(true)
            await logout()
            toast({
                title: "Logged out successfully",
                description: "You have been logged out of your account.",
            })
            navigate('/login')
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "There was a problem logging out.",
            })
        } finally {
            setIsLoading(false)
        }
    }

    const initials = user.name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-full w-full p-2 justify-start">
                    <Avatar className="h-8 w-8 mr-2">
                        <AvatarImage src={user.avatarUrl} alt={user.name} />
                        <AvatarFallback>{initials}</AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col items-start">
                        <span className="text-sm font-medium">{user.name}</span>
                        <span className="text-xs text-muted-foreground">{user.role}</span>
                    </div>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" side="right">
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem>
                        <User className="mr-2 h-4 w-4" />
                        <span>Profile</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Settings className="mr-2 h-4 w-4" />
                        <span>Settings</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    onClick={handleLogout}
                    className="text-red-600"
                    disabled={isLoading}
                >
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>{isLoading ? 'Logging out...' : 'Log out'}</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
} 