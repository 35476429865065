import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/auth-context'

export function PublicRoute({ children }: { children: React.ReactNode }) {
    const { isAuthenticated } = useAuth()
    const location = useLocation()

    // If user is authenticated, redirect to dashboard or the page they came from
    if (isAuthenticated) {
        // Get the redirect path from location state or default to dashboard
        const from = (location.state as { from?: string })?.from || '/dashboard'
        return <Navigate to={from} replace />
    }

    return <>{children}</>
} 