import { createContext, useContext, useState, useEffect, ReactNode } from "react"
import axios from 'axios'
import { API_BASE_URL } from '../config'

interface User {
    name: string
    email: string
    role: string
    avatarUrl?: string
}

interface AuthContextType {
    user: User | null
    login: (token: string) => void
    logout: () => void
    isAuthenticated: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<User | null>(null)
    const [isLoading, setIsLoading] = useState(true)

    // Check for existing token when the app loads
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('authToken')
            if (token) {
                try {
                    // You can add an API call here to validate the token and get user data
                    // const response = await axios.get(`${API_BASE_URL}/users/me`, {
                    //   headers: { Authorization: `Bearer ${token}` }
                    // });
                    // setUser(response.data);

                    // For now, we'll just set a dummy user
                    setUser({
                        name: "John Doe",
                        email: "john@example.com",
                        role: "Admin",
                        avatarUrl: "https://github.com/shadcn.png",
                    })
                } catch (error) {
                    console.error('Error validating token:', error)
                    localStorage.removeItem('authToken')
                    setUser(null)
                }
            }
            setIsLoading(false)
        }

        checkAuth()
    }, [])

    const login = (token: string) => {
        // Store the token
        localStorage.setItem('authToken', token)

        // Set user data
        // You might want to decode the token or make an API call to get user data
        setUser({
            name: "John Doe",
            email: "john@example.com",
            role: "Admin",
            avatarUrl: "https://github.com/shadcn.png",
        })
    }

    const logout = async () => {
        try {
            const token = localStorage.getItem('authToken')
            if (token) {
                await axios.post(
                    `${API_BASE_URL}/users/logout`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
            }
        } catch (error) {
            console.error('Logout error:', error)
        } finally {
            // Clear local storage and state regardless of API call success
            localStorage.removeItem('authToken')
            setUser(null)
        }
    }

    if (isLoading) {
        return <div>Loading...</div> // Or your loading component
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                logout,
                isAuthenticated: !!user,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider")
    }
    return context
} 