import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Check, X, Menu } from "lucide-react"
import { Switch } from "@/components/ui/switch"
import { cn } from "@/lib/utils"
import { useNavigate } from "react-router-dom"

export function PricingPage() {
    const [isAnnual, setIsAnnual] = useState(true)
    const [selectedTier, setSelectedTier] = useState<string>('standard')
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const navigate = useNavigate()

    const tiers = [
        {
            name: "Free",
            id: "free",
            price: {
                monthly: 0,
                annual: 0
            },
            description: "For individuals or small teams just getting started",
            features: [
                "Up to 10 users",
                "Basic project management",
                "2GB storage",
                "Email support"
            ],
            popular: false
        },
        {
            name: "Standard",
            id: "standard",
            price: {
                monthly: 7.50,
                annual: 75
            },
            description: "For growing teams that need more features",
            features: [
                "Unlimited users",
                "Advanced project tracking",
                "10GB storage",
                "Priority support",
                "Custom workflows",
                "Advanced reporting"
            ],
            popular: true
        },
        {
            name: "Premium",
            id: "premium",
            price: {
                monthly: 14.50,
                annual: 145
            },
            description: "For organizations that need enterprise features",
            features: [
                "Everything in Standard",
                "Unlimited storage",
                "24/7 phone support",
                "SAML SSO",
                "Advanced security",
                "Custom training"
            ],
            popular: false
        }
    ]

    const handleSelectPlan = (tierId: string) => {
        setSelectedTier(tierId)
        navigate('/signup', { state: { selectedTier: tierId } })
    }

    const featureCategories = [
        {
            name: "Core Features",
            features: [
                {
                    name: "Number of users",
                    free: "Up to 10",
                    standard: "Unlimited",
                    premium: "Unlimited"
                },
                {
                    name: "Storage",
                    free: "2GB",
                    standard: "10GB",
                    premium: "Unlimited"
                },
                {
                    name: "Projects",
                    free: "3",
                    standard: "Unlimited",
                    premium: "Unlimited"
                }
            ]
        },
        {
            name: "Project Management",
            features: [
                {
                    name: "Task management",
                    free: "✓",
                    standard: "✓",
                    premium: "✓"
                },
                {
                    name: "Custom workflows",
                    free: "✕",
                    standard: "✓",
                    premium: "✓"
                },
                {
                    name: "Advanced reporting",
                    free: "✕",
                    standard: "✓",
                    premium: "✓"
                }
            ]
        },
        {
            name: "Security & Support",
            features: [
                {
                    name: "Priority support",
                    free: "✕",
                    standard: "✓",
                    premium: "✓"
                },
                {
                    name: "SAML SSO",
                    free: "✕",
                    standard: "✕",
                    premium: "✓"
                },
                {
                    name: "24/7 phone support",
                    free: "✕",
                    standard: "✕",
                    premium: "✓"
                }
            ]
        }
    ]

    return (
        <div className="container min-h-screen bg-gray-50">
            {/* Header */}
            <header className="bg-white border-b sticky top-0 z-50">
                <div className="container mx-auto">
                    <div className="flex items-center justify-between h-16 px-4">
                        <div className="flex items-center">
                            <img
                                src="/logo.svg"
                                alt="Company Logo"
                                className="h-8 w-auto mr-8"
                            />
                            <nav className="hidden md:flex items-center space-x-8">
                                <a href="#features" className="text-sm font-medium text-gray-700 hover:text-gray-900">
                                    Features
                                </a>
                                <a href="#product-guide" className="text-sm font-medium text-gray-700 hover:text-gray-900">
                                    Product Guide
                                </a>
                                <a href="#pricing" className="text-sm font-medium text-gray-700 hover:text-gray-900">
                                    Pricing
                                </a>
                            </nav>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Button variant="outline">Log in</Button>
                            <Button>Get it free</Button>
                            <button
                                className="md:hidden"
                                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                            >
                                <Menu className="h-6 w-6" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container mx-auto px-4 py-16 max-w-7xl">
                {/* Pricing Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold tracking-tight mb-4">
                        Choose the right plan for your team
                    </h1>
                    <p className="text-lg text-gray-600 mb-8">
                        Start with a 30-day free trial. No credit card required.
                    </p>

                    {/* Billing Toggle */}
                    <div className="flex items-center justify-center gap-3 mb-8">
                        <span className={cn(
                            "text-sm font-medium",
                            !isAnnual ? "text-gray-900" : "text-gray-500"
                        )}>
                            Monthly
                        </span>
                        <Switch
                            checked={isAnnual}
                            onCheckedChange={setIsAnnual}
                        />
                        <span className={cn(
                            "text-sm font-medium",
                            isAnnual ? "text-gray-900" : "text-gray-500"
                        )}>
                            Annually
                            <Badge variant="secondary" className="ml-2">
                                Save 20%
                            </Badge>
                        </span>
                    </div>
                </div>

                {/* Pricing Cards */}
                <div className="grid md:grid-cols-3 gap-8 mb-16">
                    {tiers.map((tier) => (
                        <div
                            key={tier.id}
                            className={`relative bg-white rounded-lg shadow-sm ring-1 ${tier.popular
                                    ? 'ring-2 ring-primary shadow-lg scale-105'
                                    : 'ring-gray-200 hover:shadow-lg transition-shadow'
                                }`}
                        >
                            {tier.popular && (
                                <Badge
                                    className="absolute -top-2 right-4"
                                    variant="default"
                                >
                                    Most Popular
                                </Badge>
                            )}

                            <div className="p-8">
                                <div className="mb-6">
                                    <h3 className="text-xl font-bold mb-2">{tier.name}</h3>
                                    <p className="text-sm text-gray-500 mb-4">{tier.description}</p>
                                    <div className="flex items-baseline gap-x-2">
                                        <span className="text-4xl font-bold">
                                            ${isAnnual ? tier.price.annual : tier.price.monthly}
                                        </span>
                                        <span className="text-gray-500">
                                            /{isAnnual ? 'year' : 'month'}
                                        </span>
                                    </div>
                                </div>

                                <Button
                                    className="w-full mb-6"
                                    variant={tier.popular ? "default" : "outline"}
                                    onClick={() => handleSelectPlan(tier.id)}
                                >
                                    {tier.price.monthly === 0
                                        ? "Start for free"
                                        : "Start trial (30 days free)"}
                                </Button>

                                <ul className="space-y-3">
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex items-start gap-x-3">
                                            <Check className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                                            <span className="text-sm text-gray-600">{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Feature Comparison */}
                <div className="mt-16 bg-white rounded-lg shadow-sm">
                    <div className="px-6 py-8">
                        <h2 className="text-2xl font-bold text-center mb-8">
                            Compare plan features
                        </h2>
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b">
                                        <th className="text-left py-4 px-6 font-medium text-gray-500">Features</th>
                                        <th className="text-left py-4 px-6 font-medium text-gray-500">Free</th>
                                        <th className="text-left py-4 px-6 font-medium text-gray-500">Standard</th>
                                        <th className="text-left py-4 px-6 font-medium text-gray-500">Premium</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {featureCategories.map((category) => (
                                        <>
                                            <tr key={category.name} className="bg-gray-50">
                                                <td
                                                    colSpan={4}
                                                    className="py-4 px-6 font-semibold text-gray-900"
                                                >
                                                    {category.name}
                                                </td>
                                            </tr>
                                            {category.features.map((feature) => (
                                                <tr key={feature.name} className="border-b">
                                                    <td className="py-4 px-6 text-sm text-gray-900">
                                                        {feature.name}
                                                    </td>
                                                    <td className="py-4 px-6 text-sm text-gray-500">
                                                        {feature.free === "✓" ? (
                                                            <Check className="h-5 w-5 text-green-500" />
                                                        ) : feature.free === "✕" ? (
                                                            <X className="h-5 w-5 text-gray-300" />
                                                        ) : (
                                                            feature.free
                                                        )}
                                                    </td>
                                                    <td className="py-4 px-6 text-sm text-gray-500">
                                                        {feature.standard === "✓" ? (
                                                            <Check className="h-5 w-5 text-green-500" />
                                                        ) : feature.standard === "✕" ? (
                                                            <X className="h-5 w-5 text-gray-300" />
                                                        ) : (
                                                            feature.standard
                                                        )}
                                                    </td>
                                                    <td className="py-4 px-6 text-sm text-gray-500">
                                                        {feature.premium === "✓" ? (
                                                            <Check className="h-5 w-5 text-green-500" />
                                                        ) : feature.premium === "✕" ? (
                                                            <X className="h-5 w-5 text-gray-300" />
                                                        ) : (
                                                            feature.premium
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 