import { Routes, Route, Navigate } from "react-router-dom"
import { LoginPage } from "./pages/login"
import { DashboardLayout } from "./components/layouts/dashboard-layout"
import { DashboardPage } from "./pages/dashboard"
import { UsersPage } from "./pages/users"
import { CustomersPage } from "./pages/customers"
import { ClientNode } from "./pages/NodesPage";
import { CombinedLogsPage } from "./pages/logs"
import { SettingsPage } from "./pages/settings"
import { ServiceDeskPage } from "./pages/service-desk"
import { SupportPage } from "./pages/support"
import { ProtectedRoute } from "./components/protected-route"
import { PublicRoute } from "./components/public-route"
import { PricingPage } from "./pages/pricing"
import { SignupPage } from "./pages/signup"
import { NodesPage } from "./pages/nodes"
import { NodeDetailsPage } from "./pages/node-details"
import { NodeSettingsPage } from "./pages/node-settings"

export function AppRoutes() {
    return (
        <Routes>
            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <LoginPage />
                    </PublicRoute>
                }
            />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <DashboardLayout />
                    </ProtectedRoute>
                }
            >
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="customers" element={<CustomersPage />} />
                <Route path="logs" element={<CombinedLogsPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route path="support" element={<SupportPage />} />
                <Route path="service" element={<ServiceDeskPage />} />
                <Route path="nodes" element={<NodesPage />} />
                <Route path="/customers/:customerId/nodes" element={<ClientNode />} />
                <Route path="nodes/:id" element={<NodeDetailsPage />} />
                <Route path="nodes/:id/settings" element={<NodeSettingsPage />} />
            </Route>
            <Route
                path="*"
                element={
                    <PublicRoute>
                        <Navigate to="/login" replace />
                    </PublicRoute>
                }
            />
        </Routes>
    )
}