import {
    LayoutDashboard,
    Users,
    Building2,
    ScrollText,
    Settings,
    Network,
} from "lucide-react"
import {
    Sidebar,
    SidebarContent,
    SidebarHeader,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuButton,
} from "./ui/sidebar"
import { Link, useLocation } from "react-router-dom"
import { UserProfile } from "./user-profile"

const menuItems = [
    {
        title: "Dashboard",
        icon: LayoutDashboard,
        href: "/dashboard"
    },
    {
        title: "Users",
        icon: Users,
        href: "/users"
    },
    {
        title: "Nodes",
        icon: Network,
        href: "/nodes"
    },
    {
        title: "Customers",
        icon: Building2,
        href: "/customers"
    },
    {
        title: "Logs",
        icon: ScrollText,
        href: "/logs"
    },
    {
        title: "Support",
        icon: Settings,
        href: "/support"
    },
    {
        title: "Settings",
        icon: Settings,
        href: "/settings"
    },
    {
        title: "Service",
        icon: Settings,
        href: "/service"
    }
]

export function AppSidebar({ onLogout }: { onLogout: () => void }) {
    const location = useLocation()

    // Mock user data - replace with your actual user data
    const user = {
        name: "John Doe",
        email: "john.doe@example.com",
        role: "Admin",
        avatarUrl: "https://github.com/shadcn.png", // Example avatar URL
    }

    return (
        <Sidebar>
            <SidebarHeader className="border-b px-4 py-2">
                <h2 className="text-lg font-semibold">Admin Dashboard</h2>
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupLabel>Menu</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            {menuItems.map((item) => (
                                <SidebarMenuItem key={item.href}>
                                    <SidebarMenuButton asChild>
                                        <Link
                                            to={item.href}
                                            className={location.pathname === item.href ? "bg-accent" : ""}
                                        >
                                            <item.icon className="h-4 w-4" />
                                            <span>{item.title}</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                            ))}
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>
            <SidebarFooter className="border-t p-2">
                <UserProfile user={user} onLogout={onLogout} />
            </SidebarFooter>
        </Sidebar>
    )
} 