import { useState, useEffect } from "react"
import { AppSidebar } from "../components/app-sidebar"
import { SidebarTrigger } from "../components/ui/sidebar"
import { Loader2 } from "lucide-react"
import api from "@/utils/api"
import { useToast } from "@/components/ui/use-toast"

interface CompanyResponse {
    message: string
    company: {
        domain: string
        registrationCode: string
    }
}

export function DashboardPage() {
    const [registrationCode, setRegistrationCode] = useState<string>("")
    const [isLoading, setIsLoading] = useState(true)
    const { toast } = useToast()

    useEffect(() => {
        const fetchRegistrationCode = async () => {
            try {
                const response = await api.get<CompanyResponse>('/users/company/registration-code')
                setRegistrationCode(response.data.company.registrationCode)
            } catch (error: any) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: error.response?.data?.message || "Failed to fetch registration code"
                })
            } finally {
                setIsLoading(false)
            }
        }

        fetchRegistrationCode()
    }, [])

    return (
        <div className="flex min-h-screen">
            <AppSidebar />
            <main className="flex-1">
                <div className="container mx-auto p-6">
                    <div className="flex items-center gap-4 mb-8">
                        <SidebarTrigger />
                        <h1 className="text-2xl font-bold">Dashboard Overview</h1>
                    </div>
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                        <div className="rounded-lg border p-4">
                            <h3 className="font-semibold">Total Users</h3>
                            <p className="text-2xl">1,234</p>
                        </div>
                        <div className="rounded-lg border p-4">
                            <h3 className="font-semibold">Active Clients</h3>
                            <p className="text-2xl">567</p>
                        </div>
                        <div className="rounded-lg border p-4">
                            <h3 className="font-semibold">Registration Code</h3>
                            {isLoading ? (
                                <div className="flex items-center justify-center h-8">
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                </div>
                            ) : (
                                <p className="text-2xl font-mono">{registrationCode}</p>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
} 