import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../utils/api";

interface Node {
  id: string;
  siteUrl: string;
  status: string;
  frequency: string;
  moduleKey: string;
}

export function ClientNode() {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { company } = useParams<{ company: string }>();

  useEffect(() => {
    const fetchNodes = async () => {
      setLoading(true);
      setErrorMessage(null);

      try {
        const response = await api.get(`/nodes/all-nodes?companyId=${company}`);
        setNodes(response.data.nodes);
      } catch (error) {
        setErrorMessage("Failed to fetch nodes. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchNodes();
  }, [company]);

  return (
    <div className="space-y-4">
      <h1 className="text-2xl font-bold">Nodes for Company {company}</h1>
      <div className="rounded-lg border p-4">
        <h2 className="text-lg font-semibold mb-4">Nodes List</h2>

        {loading && <p>Loading nodes...</p>}
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}

        {!loading && !errorMessage && (
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">Sr. No.</th>
                <th className="border border-gray-300 px-4 py-2">Site URL</th>
                <th className="border border-gray-300 px-4 py-2">Status</th>
                <th className="border border-gray-300 px-4 py-2">Frequency</th>
                <th className="border border-gray-300 px-4 py-2">Module Key</th>
              </tr>
            </thead>
            <tbody>
              {nodes.map((node, index) => (
                <tr key={node.id} className="text-center hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                  <td className="border border-gray-300 px-4 py-2">{node.siteUrl}</td>
                  <td className="border border-gray-300 px-4 py-2">{node.status}</td>
                  <td className="border border-gray-300 px-4 py-2">{node.frequency}</td>
                  <td className="border border-gray-300 px-4 py-2">{node.moduleKey}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
