"use client"

import { Card, CardContent, CardDescription, CardHeader, CardTitle,} from "@/components/ui/card"
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel"
import { ScrollArea } from "@/components/ui/scroll-area" // Importing ScrollArea
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LabelList,} from "recharts";
import metricLabels from "@/labels.json"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table"

interface Metric {
  _id: string
  triggerKey: string
  isLatest: boolean
  data: Record<string, any>
  timestamp: string
}
interface PriorityResolutionData {
  priority: string;
  averageResolutionTime: number;
}
interface DerivedMetricsProps {
  triggerCount?: number
  metrics: Metric[]
}
type MetricLabels = Record<string, string>

// Function to format metrics for display
const formatMetrics = (metrics: Metric[], labels: MetricLabels) => {
  return metrics.map((metric) => {
    const firstValue = Object.values(metric.data)[0]
    const isNumeric = typeof firstValue === "number"

    // Handle tabular data
    const isTabularMetric = [
      "issue-StatusDistri",
      "total-WorkflowSteps",
      "project-ProdAnalysis",
      "issue-TypeDistri",
      "screen-ProjectCount",
      "analyze-PriorityRes", 
      "custom-FieldsUsageAna",
    ].includes(metric.triggerKey)

    return {
      label: labels[metric.triggerKey as keyof MetricLabels] || metric.triggerKey,
      value:
        metric.triggerKey === "backlog-GrowthRate"
          ? `${metric.data.backlogGrowthRate}%`
          : isNumeric && !isTabularMetric
          ? firstValue
          : null,
      backlogTrend: metric.triggerKey === "issue-ClosureBacklog" ? metric.data.backlogTrend : null,
      closureRate: metric.triggerKey === "issue-ClosureBacklog" ? metric.data.closureRate : null,
      fullData: isNumeric || metric.triggerKey === "backlog-GrowthRate" || !isTabularMetric
        ? null
        : metric.data,
      isTabular: isTabularMetric,
      triggerKey: metric.triggerKey,
    }
  })
}

// Function to format and extract the 'analyze-PriorityRes' data
const formatPriorityResolutionData = (metrics: Metric[]) => {
  const priorityResolutionMetric = metrics.find(
    (metric) => metric.triggerKey === "analyze-PriorityRes"
  );

  if (priorityResolutionMetric && priorityResolutionMetric.data) {
    const priorityResolutionData = priorityResolutionMetric.data.priorityResolutionAverages;

    return priorityResolutionData || []; // Ensure you return an empty array if there's no data
  }

  return [];
};

export function DerivedMetrics({ triggerCount, metrics }: DerivedMetricsProps) {
  const formattedMetrics = formatMetrics(metrics, metricLabels)

  const regularMetrics = formattedMetrics.filter(
    (metric) =>
      !metric.isTabular &&
      metric.triggerKey !== "custom-FieldsUsageAna" &&
      metric.triggerKey !== "average-IssueAging"
  );
  const tabularMetrics = formattedMetrics.filter((metric) => metric.isTabular)


  const issueTypeDistriData = tabularMetrics.find(
    (metric) => metric.triggerKey === "issue-TypeDistri"
  )?.fullData?.issueTypeDistribution

  const issueStatusDistriData = tabularMetrics.find(
    (metric) => metric.triggerKey === "issue-StatusDistri"
  )?.fullData?.statusDistribution

  const customFieldsMetric = tabularMetrics.find(
    (metric) => metric.triggerKey === "custom-FieldsUsageAna"
  )?.fullData?.customFieldsWithCounts;

  const customFieldsUsageMetric = tabularMetrics.find(
    (metric) => metric.triggerKey === "custom-FieldsUsageAna"
  )?.fullData

  const sortedCustomFields = customFieldsMetric
    ? Object.entries(customFieldsMetric).sort((a, b) => {
        const countA = a[1] as number
        const countB = b[1] as number
        return countB - countA // Sort in descending order
      })
    : []

  // Get the 'analyze-PriorityRes' data from the formatted metrics
  const priorityResolutionData = formatPriorityResolutionData(metrics);

  // Convert issueTypeDistriData into BarChart-friendly format
  const barChartIssueTypeData = issueTypeDistriData
    ? Object.entries(issueTypeDistriData)
        .filter(([_, value]) => typeof value === "number" && value > 0)
        .map(([key, value]) => ({
          name: key,
          value: value as number,
        }))
    : [];

  const barChartIssueStatusData = issueStatusDistriData
    ? Object.entries(issueStatusDistriData)
        .filter(([_, value]) => typeof value === "number" && value > 0)
        .map(([key, value]) => ({
          name: key,
          value: value as number,
        }))
    : [];

    const renderCustomizedLabel = (props: any) => {
      const { x, y, stroke, value } = props;
      return (
        <text
          x={x + 5} // Slight offset to the right of the bar
          y={y - 5} // Slight offset above the bar
          fill={stroke}
          fontSize={12}
        >
          {value}
        </text>
      );
    };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl">Derived Metrics</CardTitle>
        <CardDescription>
          Overview of derived metrics, bar charts visualization, and tabular data sections.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {/* Bar Chart Section */}
        <div className="mb-6">
          <Card>
            <CardHeader>
              <CardTitle className="text-xl">Issue Type Distribution</CardTitle>
            </CardHeader>
            <CardContent>
              {barChartIssueTypeData.length > 0 ? (
                <BarChart
                  width={700}
                  height={400}
                  data={barChartIssueTypeData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid stroke="#f0f0f0" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8">
                    <LabelList
                      dataKey="value"
                      position="top"
                      style={{ fill: "#000", fontSize: 12 }}
                    />
                  </Bar>
                </BarChart>
              ) : (
                <p className="text-center text-sm text-gray-500">No Issue Type Data to Display</p>
              )}
            </CardContent>
          </Card>
        </div>

        {/* Status Distribution Bar Section */}
        <div className="mb-6">
          <Card>
            <CardHeader>
              <CardTitle className="text-xl">Issue Status Distribution</CardTitle>
            </CardHeader>
            <CardContent>
              {barChartIssueStatusData.length > 0 ? (
                <BarChart
                  width={700}
                  height={400}
                  data={barChartIssueStatusData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid stroke="#f0f0f0" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#82ca9d">
                    <LabelList
                      dataKey="value"
                      position="top"
                      style={{ fill: "#000", fontSize: 12 }}
                    />
                  </Bar>
                </BarChart>
              ) : (
                <p className="text-center text-sm text-gray-500">No Issue Status Data to Display</p>
              )}
            </CardContent>
          </Card>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-300 mt-6 mb-6 px-4" />
        

        {/* Regular Metrics */}
        <div className="grid grid-cols-3 gap-4 mb-6 px-4">
          {regularMetrics.map((metric, index) => (
            <Carousel key={index} className="w-full h-auto">
              <CarouselContent>
                <CarouselItem>
                <Card key={index} className="border-l border-r">
                    <CardContent className="flex items-center justify-center h-32">
                      <div className="text-center">
                        <p className="text-lg font-medium">{metric.label}</p>
                        {metric.triggerKey === "issue-ClosureBacklog" && (
                          <div>
                            {metric.closureRate && (
                              <p className="text-lg font-medium">{metric.closureRate}</p>
                            )}
                            {metric.backlogTrend && (
                              <p
                                className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                                  metric.backlogTrend === "Growing"
                                    ? "bg-green-100 text-green-800"
                                    : "bg-red-100 text-red-800"
                                }`}
                              >
                                {metric.backlogTrend}
                              </p>
                            )}
                          </div>
                        )}
                        {metric.triggerKey !== "issue-ClosureBacklog" && metric.value && (
                          <p className="text-lg font-medium">{metric.value}</p>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          ))}
        </div>

        {/* Divider */}
        <div className="border-t border-gray-300 mt-6 mb-6 px-4" />

        {/* Custom Fields Usage Card */}
      {customFieldsMetric && customFieldsUsageMetric && (
        <div className="mb-6">
          <Card>
            <CardHeader>
              <CardTitle className="text-xl">Custom Fields Usage</CardTitle>
            </CardHeader>
            <CardContent>
              {/* Additional Fields */}
                <strong>Total Custom Fields:</strong>{" "}
                {customFieldsUsageMetric.totalCustomFields}
             
              <p className="text-sm">
                <strong>Zero Count Fields:</strong>{" "}
                {customFieldsUsageMetric.zeroCountFields}
              </p>
              <p className="text-sm">
                <strong>Zero Count Percentage:</strong>{" "}
                {customFieldsUsageMetric.zeroCountPercentage}%
              </p>

              <br></br>
              <ScrollArea className="h-72">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Field Name</TableHead>
                      <TableHead>Count</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedCustomFields.map(([fieldName, count], idx) => (
                      <TableRow key={idx}>
                        <TableCell>{fieldName}</TableCell>
                        <TableCell>{count as React.ReactNode}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ScrollArea>
              
            </CardContent>
          </Card>
        </div>
      )}


        {/* Tabular Metrics Section */}


        {/* New Table for analyze-PriorityRes Metric */}
        {priorityResolutionData && (
          <div className="mb-6">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Priority Resolution Time</CardTitle>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Priority</TableHead>
                      <TableHead>Average Resolution Time</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {priorityResolutionData.map((item: PriorityResolutionData, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell>{item.priority}</TableCell>
                        <TableCell>{item.averageResolutionTime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </div>
        )}

        <div>
          {tabularMetrics.map((metric, index) => {
            if (
              metric.triggerKey === "screen-ProjectCount" &&
              metric.fullData?.screenSchemeProjectCounts
            ) {
              return (
                <div key={index} className="mb-6">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-xl">{metric.label}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ScrollArea className="h-72">
                        <div className="overflow-x-auto">
                          <table className="min-w-full text-sm border border-gray-300">
                            <thead>
                              <tr>
                                <th className="border-b p-2 text-left">screenSchemeId</th>
                                <th className="border-b p-2 text-left">projectCount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {metric.fullData.screenSchemeProjectCounts.map(
                                (item: { screenSchemeId: number; projectCount: number }, idx: number) => (
                                  <tr key={idx}>
                                    <td className="border-b p-2">{item.screenSchemeId}</td>
                                    <td className="border-b p-2">{item.projectCount}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </ScrollArea>
                    </CardContent>
                  </Card>
                </div>
              );
            }

            if (metric.triggerKey === "total-WorkflowSteps" && metric.fullData?.workflows) {
              return (
                <div key={index} className="mb-6">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-xl">{metric.label}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ScrollArea className="h-72">
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>Name</TableHead>
                              <TableHead>Steps</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {metric.fullData.workflows.map((item: { name: string; steps: number }, idx: number) => (
                              <TableRow key={idx}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.steps}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </ScrollArea>
                    </CardContent>
                  </Card>
                </div>
              )
            }
        
            if (metric.triggerKey === "project-ProdAnalysis" && metric.fullData?.categoryData) {
              const { categoryData, mostProductiveCategory, leastProductiveCategory } = metric.fullData
        
              return (
                <div key={index} className="mb-6">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-xl">{metric.label}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>Category</TableHead>
                              <TableHead>Project Count</TableHead>
                              <TableHead>Issue Count</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            <TableRow>
                              <TableCell>{mostProductiveCategory}</TableCell>
                              <TableCell>{categoryData[mostProductiveCategory].projectCount}</TableCell>
                              <TableCell>{categoryData[mostProductiveCategory].issueCount}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{leastProductiveCategory}</TableCell>
                              <TableCell>{categoryData[leastProductiveCategory].projectCount}</TableCell>
                              <TableCell>{categoryData[leastProductiveCategory].issueCount}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </CardContent>
                  </Card>
                </div>
              )
            }
        

            return null;
          })}
        </div>
      </CardContent>
    </Card>
  );
}