import { useState, ChangeEvent } from "react"
import { useAuth } from "../contexts/auth-context"
import { useNavigate, useLocation } from "react-router-dom"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import axios from "axios"
import { API_BASE_URL } from "../config"

export function LoginPage() {
    // Form states
    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState("")
    const [showOtp, setShowOtp] = useState(false)
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    // Hooks
    const { login } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const from = (location.state as { from?: string })?.from

    // Handle email submission to get OTP
    const handleEmailSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        setIsLoading(true)
        setError("")
        setSuccessMessage("")

        try {
            const response = await axios.post(`${API_BASE_URL}/users/login`, { email })

            if (response.data && response.data.message === "OTP sent to your email") {
                setShowOtp(true)
                setSuccessMessage("OTP sent to your email. Please check your inbox.")
            } else {
                setError("An unexpected error occurred. Please try again.")
            }
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                setError(`Error: ${err.response.data.message || "An unknown error occurred"}`)
            } else {
                setError("An error occurred. Please try again.")
            }
        } finally {
            setIsLoading(false)
        }
    }

    // Handle OTP verification
    const handleVerifyOTP = async (event: React.FormEvent) => {
        event.preventDefault()
        setIsLoading(true)
        setError("")

        try {
            const response = await axios.post(`${API_BASE_URL}/users/verify-otp`, {
                email,
                otp,
            })

            if (response.data && response.data.token) {
                login(response.data.token)
                navigate(from || '/dashboard', { replace: true })
            } else {
                setError("Invalid OTP. Please try again.")
            }
        } catch (err) {
            setError("Failed to verify OTP. Please try again.")
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="h-screen w-full flex items-center justify-center bg-gray-50">
            <div className="w-full max-w-[400px] p-6 bg-white rounded-lg shadow-sm">
                <div className="space-y-2 mb-4">
                    <h1 className="text-2xl font-semibold tracking-tight">Login</h1>
                    <p className="text-sm text-muted-foreground">
                        {showOtp
                            ? "Enter the OTP sent to your email"
                            : "Enter your email to receive an OTP"}
                    </p>
                </div>

                <form onSubmit={showOtp ? handleVerifyOTP : handleEmailSubmit} className="space-y-4">
                    {!showOtp ? (
                        <div className="space-y-2">
                            <Label htmlFor="email">Email</Label>
                            <Input
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                disabled={isLoading}
                                required
                            />
                        </div>
                    ) : (
                        <div className="space-y-2">
                            <Label htmlFor="otp">OTP</Label>
                            <Input
                                id="otp"
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setOtp(e.target.value)}
                                disabled={isLoading}
                                required
                            />
                        </div>
                    )}

                    <Button className="w-full bg-[#0F172A]" type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                                {showOtp ? "Verifying..." : "Sending OTP..."}
                            </div>
                        ) : (
                            showOtp ? "Verify OTP" : "Get OTP"
                        )}
                    </Button>

                    {error && (
                        <p className="text-sm text-red-500 text-center">{error}</p>
                    )}
                    {successMessage && (
                        <p className="text-sm text-green-500 text-center">{successMessage}</p>
                    )}

                    <div className="text-center text-sm text-gray-600">
                        Don't have an account?{" "}
                        <a href="/signup" className="font-medium text-primary hover:underline">
                            Sign up
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
} 