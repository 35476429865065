import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from "./contexts/auth-context"
import { AppRoutes } from "./routes"
import { SidebarProvider } from "./components/ui/sidebar"
import { Toaster } from "./components/ui/toaster"

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <SidebarProvider>
            <AppRoutes />
          </SidebarProvider>
        </BrowserRouter>
      </AuthProvider>
      <Toaster />
    </>
  )
}

export default App