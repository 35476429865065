// import { useForm } from "react-hook-form";
// import { Button } from "@components/ui/button";
// import { Input } from "@components/ui/input";
// import { useToast } from "@/components/ui/use-toast";
// import api from "@/utils/api";

// export function SettingsPage() {
//     const { toast } = useToast();
//   const form = useForm({
//     defaultValues: {
//       partnerName: "",
//       contactInfo: "",
//       website: "",
//       supportDeskEmail: "",
//     },
//   });

//   // Handle form submission
//   const onSubmit = async (data) => {
//     try {
//       // Post data to API
//       const response = await api.post('/clients/info', data);
//       toast({
//         title: "Success",
//         description: response.data.message,
//       });
//       form.reset(data); // Reset form with the saved data
//     } catch (error) {
//       console.error('Error saving settings:', error);
//       alert('Error saving settings');
//     }
//   };

//   return (
//     <div className="space-y-4 px-4 py-6 w-full">
//       <h1 className="text-2xl font-bold">Settings</h1>
//       <div className="rounded-lg border p-4">
//         <h2 className="text-lg font-semibold mb-4">Partner Settings</h2>
//         <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
//           <div>
//             <label className="block text-sm font-medium">Partner Name</label>
//             <Input
//               {...form.register("partnerName")}
//               placeholder="Enter partner name"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium">Contact Info</label>
//             <Input
//               {...form.register("contactInfo")}
//               placeholder="Enter contact information"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium">Website</label>
//             <Input
//               {...form.register("website")}
//               placeholder="Enter website URL"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium">Support Desk Email</label>
//             <Input
//               {...form.register("supportDeskEmail")}
//               placeholder="Enter support desk email"
//             />
//           </div>
//           <Button type="submit" className="mt-4">
//             Save Settings
//           </Button>
//         </form>
//       </div>
//     </div>
//   );
// }




import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import api from "@/utils/api";

export function SettingsPage() {
  const { toast } = useToast();
  const form = useForm({
    defaultValues: {
      partnerName: "",
      contactInfo: "",
      website: "",
      supportDeskEmail: "",
    },
  });

  // Fetch existing data on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await api.get('/clients/info'); // Fetch settings data
        const data = response.data;
        form.reset(data); // Update form values with the fetched data
      } catch (error) {
        console.error("Error fetching settings:", error);
        toast({
          title: "Error",
          description: "Failed to load settings data.",
        });
      }
    };

    fetchSettings();
  }, [form]);

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      const response = await api.post('/clients/info', data); // Post data to API
      toast({
        title: "Success",
        description: response.data.message,
      });
      form.reset(data); // Reset form with the saved data
    } catch (error) {
      console.error("Error saving settings:", error);
      toast({
        title: "Error",
        description: "Failed to save settings.",
      });
    }
  };

  return (
    <div className="space-y-4 px-4 py-6 w-full">
      <h1 className="text-2xl font-bold">Settings</h1>
      <div className="rounded-lg border p-4">
        <h2 className="text-lg font-semibold mb-4">Partner Settings</h2>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Partner Name</label>
            <Input
              {...form.register("partnerName")}
              placeholder="Enter partner name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Contact Info</label>
            <Input
              {...form.register("contactInfo")}
              placeholder="Enter contact information"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Website</label>
            <Input
              {...form.register("website")}
              placeholder="Enter website URL"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Support Desk Email</label>
            <Input
              {...form.register("supportDeskEmail")}
              placeholder="Enter support desk email"
            />
          </div>
          <Button type="submit" className="mt-4">
            Save Settings
          </Button>
        </form>
      </div>
    </div>
  );
}
