import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function formatCurrency(amount: number): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(amount)
}

export function formatPercentage(value: number): string {
    return `${value.toFixed(1)}%`
}

export function formatDate(dateString: string | undefined) {
    if (!dateString) return '-'

    try {
        // Replace 'T' with space and 'Z' with empty string to handle the format
        const cleanDate = dateString.replace('T', ' ').replace('Z', '')
        const date = new Date(cleanDate)

        // Check if date is valid
        if (isNaN(date.getTime())) {
            return dateString
        }

        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date)
    } catch (error) {
        console.error('Error formatting date:', error)
        return dateString
    }
}