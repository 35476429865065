import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";

interface Customer {
  id: string;
  email: string;
  company: string;
  createdAt: string;
}

export function CustomersPage() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      setErrorMessage(null);
      const queryParams = `page=${currentPage}&limit=10`;

      try {
        const response = await api.get(`/users/all-users?${queryParams}`);
        setCustomers(response.data.users || response.data.nodes);
        setCurrentPage(response.data.pagination?.currentPage || currentPage);
        setTotalPages(response.data.pagination?.totalPages || 1);
      } catch (error) {
        setErrorMessage("Failed to fetch customers. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowClick = (customerId: string, company: string) => {
    navigate(`/customers/${customerId}/nodes?company=${company}`);
  };
  

  return (
    <div className="space-y-4">
      <h1 className="text-2xl font-bold">Customers</h1>
      <div className="rounded-lg border p-4">
        <h2 className="text-lg font-semibold mb-4">Customer List</h2>

        {loading && <p>Loading customers...</p>}
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}

        {!loading && !errorMessage && (
          <>
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2">Sr. No.</th>
                  <th className="border border-gray-300 px-4 py-2">Email</th>
                  <th className="border border-gray-300 px-4 py-2">Company ID</th>
                  <th className="border border-gray-300 px-4 py-2">Created At</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, index) => (
                  <tr
                    key={customer.id}
                    className="text-center cursor-pointer hover:bg-gray-100"
                    onClick={() => handleRowClick(customer.id, customer.company)}
                  >
                    <td className="border border-gray-300 px-4 py-2">
                      {(currentPage - 1) * 10 + index + 1}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">{customer.email}</td>
                    <td className="border border-gray-300 px-4 py-2">{customer.company}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      {new Date(customer.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-between mt-4">
              <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
